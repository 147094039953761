import React, { useContext, useEffect, useState } from "react";
import {
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  View,
  Modal,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from "react-native";

import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { Toast } from "native-base";

import { ChildrenContext } from "../../contexts/ChildrenContext";

import Colors from "../../utils/Colors";

import Button from "../../components/Button";
import Input from "../../components/Input";
import InputError from "../../components/InputError";
import DropDownComponent from "../../components/DropDown";

import {
  Container,
  InputContainer,
  TitleInput,
  ButtonsContainer,
  ModalButton,
  ModalButtonContainer,
  ModalContainer,
  ModalContent,
  ModalOverlay,
  ModalText,
  ModalTitle,
} from "./styles";
import { FormContext } from "../../contexts/FormContext";
import { Form } from "../../database/Models";
import { findFormMonth, getMonthsDiff } from "../../utils/Functions";

interface PassbookInfoProps {
  navigation: any;
}

const PassbookInfo: React.FC<PassbookInfoProps> = props => {
  const { width } = useWindowDimensions();

  const { selectedChild } = useContext(ChildrenContext);
  const { setSelectedForm } = useContext(FormContext);

  const [usePassbook, setUsePassbook] = useState<boolean | null>(null);
  const [haveValidPassbook, setHaveValidPassbook] = useState(false);
  const [lastAvaliation, setLastAvaliation] = useState("");
  const [classificationResult, setClassificationResult] = useState("");

  const [alertClassificationResult, setAlertClassificationResult] =
    useState("");

  const [usePassbookError, setUsePassbookError] = useState("");
  const [lastAvaliationError, setLastAvaliationError] = useState("");
  const [classificationResultError, setClassificationResultError] =
    useState("");

  const handleError = () => {
    if (usePassbook === null) {
      setUsePassbookError("Selecione uma opção");
      return true;
    }
    setUsePassbookError("");
    if (usePassbook) {
      if (lastAvaliation === "") {
        setLastAvaliationError("Preencha o campo última avaliação");
        return true;
      } else if (
        parseInt(lastAvaliation) >
        getMonthsDiff(selectedChild.birth_date, selectedChild.gestational_age)
      ) {
        setLastAvaliationError(
          "Não foi possível utilizar a informação da Caderneta, pois a idade informada é incompatível com a idade gestacional corrigida"
        );
        return false;
      }
    }
    setLastAvaliationError("");
    if (
      usePassbook &&
      (classificationResult === "" ||
        (classificationResult === "Alerta para o desenvolvimento" &&
          alertClassificationResult === ""))
    ) {
      setClassificationResultError("Selecione uma opção");
      return true;
    }
    setClassificationResultError("");

    return false;
  };

  const handleSubmit = () => {
    // Set and unset errors on user submit
    if (handleError()) return;

    if (selectedChild) {
      let monthsDiff = getMonthsDiff(
        selectedChild.birth_date,
        selectedChild.gestational_age
      );

      let monthsChronological = getMonthsDiff(
        selectedChild.birth_date
      )

      const _usePassbook = (usePassbook && haveValidPassbook) ? true : false

      const lastDevelopmentAge = lastAvaliation ? parseInt(lastAvaliation) : undefined

      const lastDevelopmentClassification =
        usePassbook && haveValidPassbook
          ? classificationResult === "Alerta para o desenvolvimento"
            ? alertClassificationResult
            : classificationResult
          : "";

      const newForm: Form = {
        months: monthsDiff,
        months_corrected: monthsDiff,
        months_chronological: monthsChronological,
        date: new Date(),
        child_id: selectedChild.id,
        use_passbook: _usePassbook,
        last_development_age: lastDevelopmentAge,
        last_development_classification: lastDevelopmentClassification,
      };

      setSelectedForm(newForm);

      props.navigation.navigate("FormStep2");
    } else {
      Toast.show({
        title: "Não sabemos o que aconteceu, volte e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  const handleHaveValidPassbook = () => {
    const monthsDiff = getMonthsDiff(
      selectedChild.birth_date,
      selectedChild.gestational_age
    );
    const monthLastAvaliation = parseInt(lastAvaliation);

    if (parseInt(lastAvaliation) > monthsDiff) {
      setLastAvaliationError(
        "Não foi possível utilizar a informação da Caderneta, pois a idade informada é incompatível com a idade gestacional corrigida"
      );
      setHaveValidPassbook(false);
      return;
    } else setLastAvaliationError("");

    if (
      (monthsDiff - monthLastAvaliation < 3 && monthsDiff < 24) ||
      (monthsDiff - monthLastAvaliation < 6 && monthsDiff >= 24)
    ) {
      setHaveValidPassbook(true);
    } else {
      setHaveValidPassbook(false);
    }
  };

  useEffect(() => {
    handleHaveValidPassbook();
    if (
      classificationResult &&
      ![
        "Desenvolvimento adequado",
        "Provável atraso do desenvolvimento",
      ].includes(classificationResult)
    ) {
      setClassificationResult("Alerta para o desenvolvimento");
    }
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Container>
            <InputContainer>
              <TitleInput marginTop="10px">
                Você gostaria de usar as informações da Caderneta da Criança
                nesta avaliação?
              </TitleInput>
              <DropDownComponent
                items={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                zIndex={9999}
                onChangeItem={(item: { value: string }) => {
                  if (item.value === "") setUsePassbook(null);
                  else setUsePassbook(item.value === "true");
                }}
              />
              {usePassbookError !== "" && (
                <InputError text={usePassbookError} />
              )}
              {usePassbook && (
                <View>
                  <TitleInput>
                    A última avaliação do desenvolvimento registrada na
                    Caderneta da Criança foi aos: (em meses)
                  </TitleInput>
                  <Input
                    placeholder="Ex. 8"
                    keyboard="numeric"
                    return="done"
                    value={lastAvaliation}
                    blurOnSubmit={true}
                    onChangeText={value => setLastAvaliation(value)}
                    onEndEditing={handleHaveValidPassbook}
                    onSubmit={() => { }}
                  />
                  {lastAvaliationError !== "" && (
                      <InputError text={lastAvaliationError} />
                  )}
                </View>
              )}
              {usePassbook && (
                <View>
                  {lastAvaliationError !== "" && ( <View style={{ height: 10 }}></View> )}
                  <TitleInput>
                    A classificação do desenvolvimento da criança nesta última
                    consulta foi:
                  </TitleInput>
                  <DropDownComponent
                    defaultValue={classificationResult}
                    items={[
                      {
                        label: "Desenvolvimento adequado",
                        value: "Desenvolvimento adequado",
                      },
                      {
                        label: "Alerta para o desenvolvimento",
                        value: "Alerta para o desenvolvimento",
                      },
                      {
                        label: "Provável atraso do desenvolvimento",
                        value: "Provável atraso do desenvolvimento",
                      },
                    ]}
                    zIndex={9997}
                    onChangeItem={(item: { value: string }) =>
                      setClassificationResult(item.value)
                    }
                  />
                  {classificationResult === "Alerta para o desenvolvimento" && (
                    <>
                      <View style={{ height: 10 }}></View>
                      <DropDownComponent
                        defaultValue={alertClassificationResult}
                        items={[
                          {
                            label:
                              "Desenvolvimento adequado com fatores de risco",
                            value:
                              "Desenvolvimento adequado com fatores de risco",
                          },
                          {
                            label: "Ausência de marcos da faixa etária",
                            value: "Ausência de marcos da faixa etária",
                          },
                        ]}
                        zIndex={9996}
                        onChangeItem={(item: { value: string }) =>
                          setAlertClassificationResult(item.value)
                        }
                      />
                    </>
                  )}
                  {classificationResultError !== "" && (
                    <InputError text={classificationResultError} />
                  )}
                </View>
              )}
            </InputContainer>
            <ButtonsContainer style={{ zIndex: 9996 }}>
              <Button text="Avançar" onPress={() => handleSubmit()} />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default PassbookInfo;
