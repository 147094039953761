import React, { useEffect, useState } from "react";
import {
  View,
  TextInput,
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  ReturnKeyTypeOptions,
  TextInputSubmitEditingEventData,
} from "react-native";

import Icon from "react-native-vector-icons/Ionicons";

import { TextInputMask, TextInputMaskTypeProp } from "react-native-masked-text";

import Colors from "../../utils/Colors";

import { styles } from "./styles";

interface PropTypes {
  keyboard: KeyboardTypeOptions | undefined;
  return: ReturnKeyTypeOptions | undefined;
  value: string;
  placeholder?: string;
  onChangeText: (text: string) => void;
  onSubmit: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
  secureTextEntry?: boolean;
  mask?: TextInputMaskTypeProp;
  inputRef?: React.RefObject<TextInput> | React.RefObject<TextInputMask>;
  blurOnSubmit?: boolean;
  maxLength?: number | undefined;
  textarea?: boolean | undefined;
  editable?: boolean | undefined;
  onEndEditing?: any;
}

const Input: React.FC<PropTypes> = props => {
  const [isHidden, setIsHidden] = useState(
    props.secureTextEntry != undefined ? true : false
  );

  const datetimeOption = {
    format: "DD/MM/YYYY",
  };

  return (
    <View style={styles.container}>
      {props.mask ? (
        <TextInputMask
          style={styles.input}
          keyboardType={props.keyboard}
          returnKeyType={props.return}
          placeholder={props.placeholder != undefined ? props.placeholder : ""}
          placeholderTextColor="#bbb"
          onChangeText={props.onChangeText}
          value={props.value}
          editable={props.editable ?? true}
          onSubmitEditing={props.onSubmit}
          secureTextEntry={isHidden}
          type={props.mask ?? "custom"}
          options={props.mask == "datetime" ? datetimeOption : {}}
          blurOnSubmit={props.blurOnSubmit || false}
          maxLength={props.maxLength}
          onEndEditing={props.onEndEditing}
          onBlur={props.onEndEditing}
          // @ts-ignore
          ref={props.inputRef}
        />
      ) : (
        <TextInput
          style={[styles.input, props.textarea ? { height: 135, paddingVertical: 10 } : {}]}
          keyboardType={props.keyboard}
          returnKeyType={props.return}
          placeholder={props.placeholder != undefined ? props.placeholder : ""}
          placeholderTextColor="#bbb"
          onChangeText={props.onChangeText}
          value={props.value}
          editable={props.editable ?? true}
          onSubmitEditing={props.onSubmit}
          secureTextEntry={isHidden}
          blurOnSubmit={props.blurOnSubmit || false}
          maxLength={props.maxLength}
          multiline={props.textarea || false}
          autoCapitalize={
            props.keyboard === "email-address" ? "none" : "sentences"
          }
          onEndEditing={props.onEndEditing}
          onBlur={props.onEndEditing}
          // @ts-ignore
          ref={props.inputRef}
        />
      )}
      {props.secureTextEntry ? (
        <View style={styles.eyeButton}>
          <Icon.Button
            name={isHidden ? "eye" : "eye-off"}
            onPress={() => setIsHidden(!isHidden)}
            color={Colors.mediumGray}
            backgroundColor="transparent"
            iconStyle={{ marginRight: 0 }}
            underlayColor="#DDDDDD"
            size={22}
          />
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

export default Input;
