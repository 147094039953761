export default {
  background: "#F4F5F7",
  white: "#FFFFFF",
  main: "#418FDE",
  secondary: "#86B5E3",
  darkMain: "#01126C",
  ultraDarkMain: "#384B5E",
  black: "#454E4B",
  mediumGray: "#DDDDDD",
  lightGray: "#F1F1F1",
  darkGray: "#A3A3A3",
  modalBackground: "#F8F8F8",
};

export const cardColors = ["#7B42C7", "#00A43E", "#FEC200", "#EB1A20"];
