import React from 'react';
import { View, Text } from 'react-native';
import { Inner, Wrapper } from './styles';

interface CheckboxProps {
    selected: boolean;
    wrapperRadius: string;
    innerRadius: string;
    color?: string | null;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
    return (
        <Wrapper color={props.color ?? '#418FDE'} borderRadius={props.wrapperRadius}>
            {props.selected
                ? <Inner color={props.color ?? '#418FDE'} borderRadius={props.innerRadius} />
                : <View></View>
            }
        </Wrapper>
    );
};

export default Checkbox;
