import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 0px 30px;
  background-color: ${Colors.white};
`;

export const InputContainer = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

interface TitleInputProps {
  marginTop?: string;
}

export const TitleInput = styled.Text<TitleInputProps>`
  font-family: Muli_400Regular;
  font-size: 16px;
  color: ${Colors.darkGray};
  flex-shrink: 1;
  margin-top: ${(props: TitleInputProps) => props.marginTop ?? "10px"};
  margin-bottom: 10px;
`;

export const ButtonsContainer = styled.View`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalTitle = styled.Text`
  margin-bottom: 20px;
  color: ${Colors.black};
  font-family: Muli_700Bold;
  font-size: 24px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_500Medium;
  font-size: 18px;
  color: ${Colors.darkGray};
  text-align: center;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;
