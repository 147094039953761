import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 0px 30px;
  background-color: ${Colors.white};
`;

export const MainTitleInput = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 20px;
  color: ${Colors.black};
  margin-bottom: 20px;
  text-align: center;
`;

interface TitleInputProps {
  marginTop?: string;
}

export const TitleInput = styled.Text<TitleInputProps>`
  font-family: Muli_400Regular;
  font-size: 16px;
  color: ${Colors.darkGray};
  flex-shrink: 1;
  margin-top: ${(props: TitleInputProps) => props.marginTop ?? "10px"};
  margin-bottom: 10px;
`;

export const InputContainer = styled.View`
  justify-content: flex-start;
  margin-bottom: 10px;
  z-index: 2;
`;

interface SwycContainerProps {
  bottom: string;
}

export const SwycContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const ButtonsContainer = styled.View`
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;
