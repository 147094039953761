import React, { useState, useRef } from "react";
import {
  View,
  Text,
  ScrollView,
  ImageBackground,
  Image,
  Modal,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Pressable,
  ActivityIndicator,
  Share,
  Platform,
  useWindowDimensions,
} from "react-native";

import Swiper from "react-native-web-swiper";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";
import * as ScreenOrientation from "expo-screen-orientation";
import { AVPlaybackStatus, Video, VideoFullscreenUpdateEvent } from "expo-av";
import { activateKeepAwake, deactivateKeepAwake } from "expo-keep-awake";
import { FontAwesome5 } from "@expo/vector-icons";
import { Toast } from "native-base";

import { baseURL } from "../../services/api";

import Colors from "../../utils/Colors";
import Button from "../../components/Button";
import LogoNoText from "../../components/LogoNoText";

import {
  Container,
  LogoContainer,
  Dot,
  Circle,
  ModalContainer,
  ModalOverlay,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalButtonContainer,
  ModalButton,
  VideoCard,
} from "./styles";

interface TutorialProps {
  navigation: any;
  route: any;
}

const Tutorial: React.FC<TutorialProps> = props => {
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const videoRef = useRef<Video | null>(null);
  const [videoStatus, setVideoStatus] = useState<AVPlaybackStatus>(
    {} as AVPlaybackStatus
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { width: screenWidth } = useWindowDimensions();
  const onFullscreenUpdate = async ({
    fullscreenUpdate,
  }: VideoFullscreenUpdateEvent) => {
    switch (fullscreenUpdate) {
      case Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT:
        await ScreenOrientation.unlockAsync(); // only on Android required
        break;
      case Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS:
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        ); // only on Android required
        break;
    }
  };

  const handleStatus = (value: any) => {
    if (value.isPlaying) {
      activateKeepAwake();
    } else {
      deactivateKeepAwake();
    }
  };

  const { height } = useWindowDimensions();

  const [currentIndex, setCurrentIndex] = useState(
    props.route.params?.index ?? 0
  );

  const swiperRef = useRef<Swiper>(null);

  const handleIndexChange = (index: number) => {
    setTimeout(() => {
      setCurrentIndex(index);
    }, 0);
  };

  const handleNext = () => {
    if (currentIndex < 2) swiperRef.current?.goToNext();
    else props.navigation.goBack();
  };

  const handleQuit = () => swiperRef.current?.goTo(2);

  const styles = StyleSheet.create({
    footerWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginHorizontal: 30,
    },
    buttonWrapper: {
      bottom: 0,
      paddingVertical: 30,
    },
    pagination: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  return (
    <View style={{ height: "100%", backgroundColor: Colors.white }}>
      <Swiper
        ref={swiperRef}
        from={currentIndex}
        onIndexChanged={index => handleIndexChange(index)}
        controlsProps={{
          prevPos: false,
          nextPos: false,
          dotsPos: false,
        }}>
        {/* Onboarding page 1 */}
        <View style={{ flex: 1 }}>
          {/* Content */}
          <Container>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <ImageBackground
                  style={{
                    width: 250,
                    height: 250,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  source={require("../../../assets/background_logo_shadow.png")}>
                  <LogoContainer>
                    <LogoNoText width={150} height={150} />
                  </LogoContainer>
                </ImageBackground>
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      fontFamily: "Muli_700Bold",
                      fontSize: 20,
                      marginBottom: 20,
                      marginTop: height * 0.08,
                    }}>
                    Bem vindo ao Tedi!
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      fontFamily: "Muli_400Regular",
                      fontSize: 15,
                      color: Colors.darkGray,
                    }}>
                    O Tedi é um aplicativo para uso dos profissionais de saúde
                    que permite a triagem eletrônica de problemas de
                    comportamento e desenvolvimento infantil e oferece
                    orientações para a estimulação de crianças de 1 mês aos 5
                    anos.
                  </Text>
                </View>
              </View>
            </ScrollView>
          </Container>
        </View>
        {/* Onboarding page 2 */}
        <View style={{ flex: 1 }}>
          {/* Content */}
          <Container>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <View>
                <Image
                  style={{
                    width: 222,
                    height: 222,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    resizeMode: "stretch",
                  }}
                  source={require("../../../assets/img/onboarding_1.png")}
                />
                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      fontFamily: "Muli_700Bold",
                      fontSize: 20,
                      marginBottom: 20,
                      marginTop: height * 0.08,
                    }}>
                    Como funciona?
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      fontFamily: "Muli_400Regular",
                      fontSize: 15,
                      color: Colors.darkGray,
                    }}>
                    O Tedi reúne informações da Caderneta da Criança e do Survey
                    of Well-Being of Young Children (SWYC)™ para ajudar os
                    profissionais na tomada de decisões em relação ao
                    comportamento e desenvolvimento infantil, levando em conta o
                    contexto familiar, além de disponibilizar vídeos curtos com
                    atividades e brincadeiras que ajudam na estimulação da
                    criança.
                  </Text>
                </View>
              </View>
            </ScrollView>
          </Container>
        </View>
        {/* Onboarding page 3 */}
        <View style={{ flex: 1 }}>
          {/* Content */}
          <Container>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <View>
                <Image
                  style={{
                    width: 222,
                    height: 222,
                    marginTop: height * 0.05,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  source={require("../../../assets/img/onboarding_2.png")}
                />
                <Text
                  style={{
                    textAlign: "center",
                    fontFamily: "Muli_700Bold",
                    fontSize: 20,
                    marginBottom: 20,
                    marginTop: 50,
                  }}>
                  Composição do Tedi
                </Text>
                <View>
                  <Text
                    style={{
                      textAlign: "left",
                      fontFamily: "Muli_400Regular",
                      fontSize: 15,
                      color: Colors.darkGray,
                    }}>
                    {"\u2022" + " "}Preparamos um vídeo para você entender
                    melhor o funcionamento do Tedi e explorar todas as suas
                    possibilidades.
                    <Text
                      style={{
                        fontFamily: "Muli_400Regular",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      onPress={() => setIsModalVisible(true)}>
                      {" "}
                      Clique aqui para assistir o vídeo instrucional.{"\n"}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      textAlign: "left",
                      fontFamily: "Muli_400Regular",
                      fontSize: 15,
                      color: Colors.darkGray,
                    }}>
                    {"\u2022" + " "}Para que você conheça melhor a lógica de
                    funcionamento do SWYC como ferramenta de triagem, sugerimos
                    a leitura do Manual de aplicação e Interpretação.
                    <Text
                      style={{
                        fontFamily: "Muli_400Regular",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      onPress={() =>
                        Linking.openURL(
                          "https://tedi.medicina.ufmg.br/wp-content/uploads/documentos/Manual-do-SWYC-Br.pdf"
                        )
                      }>
                      {" "}
                      Sugerimos a leitura do Manual de Aplicação e
                      Interpretação.
                      {"\n"}
                    </Text>
                  </Text>
                </View>
              </View>
            </ScrollView>
          </Container>
        </View>
      </Swiper>
      {/* Footer buttons and pagination */}
      <View style={styles.footerWrapper}>
        {/* "Pular" button */}
        <View style={styles.buttonWrapper}>
          <TouchableOpacity
            onPress={handleQuit}
            style={{
              backgroundColor: "transparent",
              paddingVertical: 6,
              borderRadius: 29,
              alignItems: "flex-start",
              width: 100,
            }}>
            {currentIndex !== 2 && (
              <Text
                style={{
                  color: "#384B5E",
                  fontFamily: "Muli_700Bold",
                  fontSize: 15,
                }}>
                Pular
              </Text>
            )}
          </TouchableOpacity>
        </View>
        {/* Pagination dots */}
        <View style={styles.pagination}>
          <Dot active={currentIndex == 0} />
          <Dot active={currentIndex == 1} />
          <Dot active={currentIndex == 2} />
        </View>
        {/* "Próximo" button */}
        <View style={styles.buttonWrapper}>
          <TouchableHighlight
            onPress={handleNext}
            underlayColor={Colors.main}
            activeOpacity={0.6}
            style={{
              backgroundColor: Colors.main,
              paddingVertical: 6,
              paddingHorizontal: 5,
              borderRadius: 29,
              alignItems: "center",
              width: 110,
            }}>
            <Text
              style={{
                color: Colors.white,
                fontFamily: "Muli_700Bold",
                fontSize: 15,
              }}>
              Próximo
            </Text>
          </TouchableHighlight>
        </View>
      </View>
      <ModalContainer>
        <Modal
          key={"sd1"}
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Vídeo instrucional</ModalTitle>
                <TouchableOpacity
                  style={{ right: -15 }}
                  onPress={() => {
                    Clipboard.default.setString(
                      `Confira o vídeo instrucional do TEDI : \nhttps://www.youtube.com/watch?v=Tvqi5SV0kDc`
                    );

                    Toast.show({
                      title: "Link copiado para a área de transferência",
                      duration: 1500,
                      isClosable: true,
                      placement: screenWidth <= 1024 ? "bottom" : "bottom-left",
                      padding: 4,
                      marginLeft: screenWidth <= 1024 ? 0 : 20,
                    });
                  }}>
                  <FontAwesome5 name="share-alt" color="#007aff" size={18} />
                </TouchableOpacity>
              </ModalHeader>

              <VideoCard>
                {/* <TouchableWithoutFeedback
                  onPress={async () => {
                    if (videoRef.current) await videoRef.current.pauseAsync();
                  }}> */}
                <View
                  onLayout={e => {
                    const newWidth = e.nativeEvent.layout.width;
                    const newHeight = e.nativeEvent.layout.height;

                    setVideoWidth(newWidth);
                    setVideoHeight(newHeight);
                  }}>
                  <Video
                    key={"3ed2"}
                    ref={component => (videoRef.current = component)}
                    source={{
                      uri: baseURL + "/video/institucional",
                    }}
                    style={{
                      // width: "100%",
                      height: (screenWidth * 0.74 * 9) / 16,
                    }}
                    usePoster
                    posterSource={{
                      uri: baseURL + "/video/thumb/institucional",
                    }}
                    posterStyle={{
                      resizeMode: "stretch",
                    }}
                    useNativeControls
                    resizeMode="contain"
                    onFullscreenUpdate={onFullscreenUpdate}
                    onPlaybackStatusUpdate={status => {
                      setVideoStatus(status);
                      handleStatus(status);
                    }}
                  />
                </View>
                {/* </TouchableWithoutFeedback> */}
                {videoStatus.isLoaded &&
                  !videoStatus.isPlaying &&
                  !videoStatus.isBuffering &&
                  videoRef.current && (
                    <Pressable
                      onPress={async () => {
                        if (videoRef.current) {
                          await videoRef.current.replayAsync({
                            positionMillis: 0,
                            shouldPlay: true,
                          });
                        }
                      }}
                      style={{
                        width: videoWidth,
                        height: videoHeight,
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <FontAwesome5
                        name="play-circle"
                        size={57}
                        color={Colors.white}
                      />
                    </Pressable>
                  )}
                {(videoStatus.isLoaded !== true ||
                  (videoStatus.isBuffering && !videoStatus.isPlaying)) && (
                  <View
                    style={{
                      width: videoWidth,
                      height: videoHeight,
                      position: "absolute",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    }}>
                    <ActivityIndicator color={Colors.white} size="large" />
                  </View>
                )}
              </VideoCard>
              <ModalButtonContainer>
                <ModalButton>
                  <Button
                    text="Fechar"
                    backgroundColor={Colors.darkGray}
                    underlayColor={Colors.black}
                    onPress={() => setIsModalVisible(false)}
                  />
                </ModalButton>
              </ModalButtonContainer>
            </ModalContent>
            {/* </View> */}
          </ModalOverlay>
        </Modal>
      </ModalContainer>
    </View>
  );
};

export default Tutorial;
