import { Child, Form } from '../database/Models';
import { getMonthsDiff } from './Functions';

export const formHasProblems = (form: Form, child: Child) => {
    // Check all the results
    if (form.developmental_milestones_result != null) {
        const age = getMonthsDiff(child.birth_date, child.gestational_age, new Date(form.date));
        if (getMilestonesResult(form.developmental_milestones_result, age))
            return true;
    }

    if (form.bpsc_result != null && getBpscResult(form.bpsc_result))
        return true;

    if (form.ppsc_result != null && getPpscResult(form.ppsc_result))
        return true;

    if (form.posi_result != null && getPosiResult(form.posi_result))
        return true;

    if (form.family_questions_result != null && getFamilyResult(form.family_questions_result))
        return true;

    if (form.epds_result != null && getEpdsResult(form.epds_result))
        return true;

    return false;
};

export const getMilestonesResult = (result: number, age: number) => {
    let passingScore = 13;
    const scores = {
        twelve: [47, 48, 49, 50, 59, 60, 61, 62], // 8
        thirteen: [
            4, 15, 18, 19, 20, 29, 30, 35, 36, 37, 38, 39, 51, 52, 53, 54, 55, 56, 57,
            58, 63, 64, 65,
        ], // 23
        fourteen: [
            1, 2, 3, 6, 7, 9, 10, 12, 13, 14, 16, 17, 21, 22, 23, 24, 25, 31, 32, 33,
            34, 40, 41, 42, 43, 44, 45, 46,
        ], // 28
        fifteen: [5, 11, 26, 27, 28], // 6
        sixteen: [8], // 1
    };

    if (scores.twelve.includes(age)) passingScore = 12;
    if (scores.thirteen.includes(age)) passingScore = 13;
    if (scores.fourteen.includes(age)) passingScore = 14;
    if (scores.fifteen.includes(age)) passingScore = 15;
    if (scores.sixteen.includes(age)) passingScore = 16;

    return result < passingScore;
};

export const getBpscResult = (result: string) =>
    parseInt(result[0]) >= 3 ||
    parseInt(result[1]) >= 3 ||
    parseInt(result[2]) >= 3;

export const getPpscResult = (result: number) => result >= 9;

export const getPosiResult = (result: number) => result >= 3;

export const getFamilyResult = (result: string) => {
    let manipulable = result[0] + "" + result[1] + "" + result[2];
    manipulable += (parseInt(result[3]) >= 3) ? '3' : '0';
    manipulable += (parseInt(result[4]) >= 2) ? '2' : '0';
    manipulable += (parseInt(result[5]) < 3) ? '1' : '0';

    return parseInt(manipulable.substring(0, 5)) > 0;
};

export const getEpdsResult = (result: number) => result >= 10;
