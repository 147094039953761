import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Modal,
  ScrollView,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from "react-native";

import { Toast } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";
import { FontAwesome5 } from "@expo/vector-icons";

import { ChildrenContext } from "../../contexts/ChildrenContext";
import { FormContext } from "../../contexts/FormContext";

import SwycLogo from "../../../assets/swyc_logo.svg";

import Colors from "../../utils/Colors";
import Input from "../../components/Input";
import Button from "../../components/Button";

import {
  Container,
  MainTitleInput,
  SurveyCard,
  SurveyName,
  SurveyAnswered,
  SwycContainer,
  TitleInput,
  ModalButton,
  ModalButtonContainer,
  ModalContainer,
  ModalContent,
  ModalOverlay,
  ModalText,
  ModalTitle,
  SwycRightsText,
} from "./styles";
import {
  findFormMonth,
  getMonthsDiff,
  getPassingScore,
} from "../../utils/Functions";

interface SwycMainProps {
  navigation: any;
  route: any;
}

const SwycMain: React.FC<SwycMainProps> = props => {
  const { width } = useWindowDimensions();

  const { selectedChild } = useContext(ChildrenContext);
  const { selectedForm, setSelectedForm, updateForm, loadSwycForm } = useContext(FormContext);

  const [editable, setEditable] = useState(true)
  const [observations, setObservations] = useState(selectedForm?.observations || '')

  const routeToNavigate = useRef("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateObservations = async () => {
    const object = { ...selectedForm };
    object.observations = observations;
    setSelectedForm(object);

    await updateForm(object);
  }

  const showToast = () => {
    Toast.show({
      title: "Você não pode editar/visualizar esse formulário",
      duration: 1500,
      isClosable: true,
      placement: width <= 1024 ? "bottom" : "bottom-left",
      padding: 4,
      marginLeft: width <= 1024 ? 0 : 20,
      status: "error",
      variant: "left-accent",
    });
  };

  const getResult = (stage: string) => {
    let result: number | undefined;
    switch (stage) {
      case "developmental_milestones":
        if (selectedForm?.developmental_milestones_result) {
          const milestonesResult =
            selectedForm?.developmental_milestones_result;

          const monthsDiff = getMonthsDiff(
            selectedForm.date,
            selectedChild.gestational_age
          );
          const passingScore = getPassingScore(monthsDiff);

          let atRisk = false;

          if (milestonesResult < passingScore) atRisk = true;

          const passbookClassification =
            selectedForm?.last_development_classification;

          if (passbookClassification && passbookClassification !== "") {
            // Get milestones result using the classification
            if (
              passbookClassification === "Desenvolvimento adequado" ||
              passbookClassification ===
              "Desenvolvimento adequado com fatores de risco"
            ) {
              result = atRisk ? 1 : 0;
            } else if (
              passbookClassification === "Ausência de marcos da faixa etária"
            ) {
              result = atRisk ? 2 : 1;
            } else {
              result = atRisk ? 3 : 2;
            }
          } else {
            // When there is no passbook available, use risk factors and parents concerns
            // to get milestones final result
            const hasRiskFactors =
              selectedForm?.risk_factors?.includes(true) ||
              selectedForm?.parents_concerns?.some(
                concerns => concerns === "Um pouco" || concerns === "Muito"
              );

            if (atRisk) {
              result = hasRiskFactors ? 3 : 2;
            } else {
              result = hasRiskFactors ? 1 : 0;
            }
          }
        }

        break;
      case "bpsc":
        if (selectedForm?.bpsc_result) {
          const bpscResult = selectedForm?.bpsc_result;
          result =
            parseInt(bpscResult[0]) >= 3
              ? 1
              : parseInt(bpscResult[1]) >= 3
                ? 1
                : parseInt(bpscResult[2]) >= 3
                  ? 1
                  : 0;
        }
        break;
      case "ppsc":
        if (selectedForm?.ppsc_result) {
          result = selectedForm.ppsc_result >= 9 ? 1 : 0;
        }
        break;
      case "posi":
        if (selectedForm?.posi_result) {
          result = selectedForm.posi_result >= 3 ? 1 : 0;
        }
        break;
      case "family_questions":
        if (selectedForm?.family_questions_result) {
          const familyQuestionsResult = selectedForm?.family_questions_result;
          result = parseInt(familyQuestionsResult);
        }
        break;
      case "epds":
        if (selectedForm?.epds_result) {
          result = selectedForm.epds_result >= 10 ? 1 : 0;
        }
      default:
        break;
    }
    return result;
  };

  const handleNavigation = (stage: string | null, navigation?: string) => {
    loadSwycForm(stage);
    const monthsDiff = getMonthsDiff(
      selectedChild.birth_date,
      selectedChild.gestational_age
    );

    switch (stage) {
      case "developmental_milestones":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.developmental_milestones_result !== null
        ) {
          // props.navigation.navigate("DecisionGuide", {
          //   stage: "developmental_milestones",
          //   result: getResult("developmental_milestones"),
          // });
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "developmental_milestones"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (
          selectedForm &&
          selectedForm.developmental_milestones_result !== null
        ) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "developmental_milestones";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      case "bpsc":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.bpsc_result !== null
        ) {
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "bpsc"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (selectedForm && selectedForm.bpsc_result !== null) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "bpsc";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      case "ppsc":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.ppsc_result !== null
        ) {
          // props.navigation.navigate("DecisionGuide", {
          //   stage: "ppsc",
          //   result: getResult("ppsc"),
          // });
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "ppsc"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (selectedForm && selectedForm.ppsc_result !== null) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "ppsc";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      case "posi":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.posi_result !== null
        ) {
          // props.navigation.navigate("DecisionGuide", {
          //   stage: "posi",
          //   result: getResult("posi"),
          // });
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "posi"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (selectedForm && selectedForm.posi_result !== null) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "posi";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      case "family_questions":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.family_questions_result !== null
        ) {
          // props.navigation.navigate("DecisionGuide", {
          //   stage: "family_questions",
          //   result: getResult("family_questions"),
          // });
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "family_questions"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (selectedForm && selectedForm.family_questions_result !== null) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "family_questions";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      case "epds":
        if (
          selectedForm &&
          selectedForm.months !== monthsDiff &&
          selectedForm.epds_result !== null
        ) {
          // props.navigation.navigate("DecisionGuide", {
          //   stage: "epds",
          //   result: getResult("epds"),
          // });
          const answers = selectedForm?.form_entries?.filter(
            entry => entry.stage === "epds"
          );

          props.navigation.navigate("FormQuestionary", {
            answers,
          });

          return;
        }

        if (selectedForm && selectedForm.epds_result !== null) {
          setIsModalVisible(!isModalVisible);
          routeToNavigate.current = "epds";

          return;
        }

        if (selectedForm && selectedForm.months === monthsDiff) {
          props.navigation.navigate("FormQuestionary");
          return;
        }
        showToast();
        break;
      default:
        // When stage is null
        switch (routeToNavigate.current) {
          case "developmental_milestones":
            setIsModalVisible(!isModalVisible);
            if (
              navigation === "guide" &&
              selectedForm?.developmental_milestones_result !== null
            ) {
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "developmental_milestones",
              //   result: getResult("developmental_milestones"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "developmental_milestones"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          case "bpsc":
            setIsModalVisible(!isModalVisible);
            if (navigation === "guide" && selectedForm?.bpsc_result !== null) {
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "bpsc",
              //   result: getResult("bpsc"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "bpsc"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          case "ppsc":
            setIsModalVisible(!isModalVisible);
            if (navigation === "guide" && selectedForm?.ppsc_result !== null) {
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "ppsc",
              //   result: getResult("ppsc"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "ppsc"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          case "posi":
            setIsModalVisible(!isModalVisible);
            if (navigation === "guide" && selectedForm?.posi_result !== null) {
              setIsModalVisible(!isModalVisible);
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "posi",
              //   result: getResult("posi"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "posi"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          case "family_questions":
            setIsModalVisible(!isModalVisible);
            if (
              navigation === "guide" &&
              selectedForm?.family_questions_result !== null
            ) {
              setIsModalVisible(!isModalVisible);
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "family_questions",
              //   result: getResult("family_questions"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "family_questions"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          case "epds":
            setIsModalVisible(!isModalVisible);
            if (navigation === "guide" && selectedForm?.epds_result !== null) {
              // props.navigation.navigate("DecisionGuide", {
              //   stage: "epds",
              //   result: getResult("epds"),
              // });
              const answers = selectedForm?.form_entries?.filter(
                entry => entry.stage === "epds"
              );

              props.navigation.navigate("FormQuestionary", {
                answers,
              });
            } else {
              props.navigation.navigate("FormQuestionary");
            }
            break;
          default:
            break;
        }
        break;
    }
  };

  useEffect(() => {
    const monthsDiff = getMonthsDiff(
      selectedChild.birth_date,
      selectedChild.gestational_age
    );

    if (selectedForm && selectedForm.months !== monthsDiff) {
      setEditable(false)
    }
  }, [])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.background }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1, paddingTop: 10 }}>
        <Container>
          <MainTitleInput>
            Formulários disponíveis para a idade da criança:
          </MainTitleInput>
          <SurveyCard
            onPress={() => handleNavigation("developmental_milestones")}>
            <SurveyName>Marcos do Desenvolvimento</SurveyName>
            {selectedForm &&
              selectedForm.developmental_milestones_result !== null && (
                <SurveyAnswered>
                  <FontAwesome5 name="check" size={12} color={Colors.white} />
                </SurveyAnswered>
              )}
          </SurveyCard>
          {selectedForm && selectedForm.months < 18 ? (
            <SurveyCard onPress={() => handleNavigation("bpsc")}>
              <SurveyName>Lista de sintomas do bebê (BPSC)</SurveyName>
              {selectedForm && selectedForm.bpsc_result !== null && (
                <SurveyAnswered>
                  <FontAwesome5 name="check" size={12} color={Colors.white} />
                </SurveyAnswered>
              )}
            </SurveyCard>
          ) : (
            <SurveyCard onPress={() => handleNavigation("ppsc")}>
              <SurveyName>Lista de sintomas pediátricos (PPSC)</SurveyName>
              {selectedForm && selectedForm.ppsc_result !== null && (
                <SurveyAnswered>
                  <FontAwesome5 name="check" size={12} color={Colors.white} />
                </SurveyAnswered>
              )}
            </SurveyCard>
          )}
          {selectedForm &&
            selectedForm.months >= 18 &&
            selectedForm.months <= 34 && (
              <SurveyCard onPress={() => handleNavigation("posi")}>
                <SurveyName>
                  Observações dos pais sobre interação social (POSI)
                </SurveyName>
                {selectedForm && selectedForm.posi_result !== null && (
                  <SurveyAnswered>
                    <FontAwesome5 name="check" size={12} color={Colors.white} />
                  </SurveyAnswered>
                )}
              </SurveyCard>
            )}
          <SurveyCard onPress={() => handleNavigation("family_questions")}>
            <SurveyName>Perguntas sobre a família</SurveyName>
            {selectedForm && selectedForm.family_questions_result !== null && (
              <SurveyAnswered>
                <FontAwesome5 name="check" size={12} color={Colors.white} />
              </SurveyAnswered>
            )}
          </SurveyCard>
          {selectedForm && selectedForm.months < 9 && (
            <SurveyCard onPress={() => handleNavigation("epds")}>
              <SurveyName>
                Mudanças emocionais com o novo bebê (EPDS)
              </SurveyName>
              {selectedForm && selectedForm.epds_result !== null && (
                <SurveyAnswered>
                  <FontAwesome5 name="check" size={12} color={Colors.white} />
                </SurveyAnswered>
              )}
            </SurveyCard>
          )}
          <TitleInput>Observações</TitleInput>
          <Input
            keyboard="default"
            return="done"
            onChangeText={text => setObservations(text)}
            value={observations}
            textarea={true}
            blurOnSubmit={true}
            onSubmit={() => { }}
            onEndEditing={updateObservations}
            editable={editable}
          />
          <View style={{ height: 10 }} />
          <SwycContainer>
            <SwycRightsText>
              www.theswyc.org ©️ 2010 Tufts Medical Center, Inc. All rights
              reserved
            </SwycRightsText>
          </SwycContainer>
          <Button
            text="Finalizar"
            onPress={() => props.navigation.navigate("TabsStack")}
          />
        </Container>
      </ScrollView>
      <ModalContainer>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <TouchableWithoutFeedback
            onPress={() => {
              setIsModalVisible(!isModalVisible);
            }}>
            <ModalOverlay>
              <ModalContent>
                <ModalTitle>Formulário Respondido</ModalTitle>

                <ModalText>
                  Esse formulário já foi respondido, deseja responder novamente
                  ou visualizar as recomendações?
                </ModalText>
                <ModalButtonContainer>
                  <ModalButton style={{ marginRight: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Responder"
                      onPress={() => handleNavigation(null, "next")}
                    />
                  </ModalButton>
                  <ModalButton style={{ marginLeft: 5, flexBasis: 'auto' }}>
                    <Button
                      text="Visualizar"
                      onPress={() => handleNavigation(null, "guide")}
                    />
                  </ModalButton>
                </ModalButtonContainer>
              </ModalContent>
            </ModalOverlay>
          </TouchableWithoutFeedback>
        </Modal>
      </ModalContainer>
    </SafeAreaView>
  );
};

export default SwycMain;
