import React from "react";
import { View, useWindowDimensions } from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";

import Logo from "../../components/Logo";
import Button from "../../components/Button";
import Close from "../../components/Close";

import Colors from "../../utils/Colors";

import { Container, LogoContainer, ButtonsContainer } from "./styles";

interface SelectSignUpProps {
  navigation: any;
}

const SelectSignUp: React.FC<SelectSignUpProps> = props => {
  const { height, width } = useWindowDimensions();

  return (
    <View style={{ height: "100%" }}>
      <SafeAreaView style={{ backgroundColor: Colors.white, flex: 1 }}>
        <Container>
          <View
            style={{
              width: width <= 1024 ? "90%" : "50%",
            }}>
            <Close onPress={() => props.navigation.goBack()} />
          </View>
          <LogoContainer
            style={{
              marginTop: height * 0.22,
              marginBottom: 0.05 * height,
              width: width <= 1024 ? "70%" : "35%",
            }}>
            <Logo width="100%" height="100%" />
          </LogoContainer>
          <ButtonsContainer style={{ width: width <= 1024 ? "90%" : "50%" }}>
            <Button
              text="Sou profissional de saúde"
              onPress={() => {
                props.navigation.navigate("signup", {
                  userType: "professional",
                });
              }}
            />
            <View style={{ height: 10, width: "100%" }} />
            <Button
              text="Outras categorias"
              disabled={true}
              onPress={() => {
                // props.navigation.navigate("signup", { userType: "caregiver" });
              }}
            />
          </ButtonsContainer>
        </Container>
      </SafeAreaView>
    </View>
  );
};

export default SelectSignUp;
