import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 0 20px 20px 20px;
  background-color: ${Colors.background};
`;

export const MainTitleInput = styled.Text`
  flex-shrink: 1;
  font-family: Muli_700Bold;
  font-size: 16px;
  color: ${Colors.black};
  margin-bottom: 20px;
  text-align: center;
`;

export const SurveyCard = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px 25px;
  border-radius: 5px;
  background-color: ${Colors.white};
  shadow-color: "rgba(138, 149, 158, 0.1)";
  shadow-offset: 0px 5px;
  shadow-radius: 10px;
  elevation: 3;
  overflow: hidden;
`;

export const SurveyName = styled.Text`
  flex: 1;
  color: ${Colors.main};
  font-family: Muli_700Bold;
  font-size: 18px;
`;

export const SurveyAnswered = styled.View`
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  margin-left: 10px;
  background-color: ${Colors.main};
`;

export const TitleInput = styled.Text`
  font-family: Muli_500Medium;
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const SwycContainer = styled.View`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

export const SwycRightsText = styled.Text`
  color: ${Colors.darkGray};
  font-size: 11px;
  font-family: Muli_700Bold;
  text-align: center;
`;

export const ModalContainer = styled.View`
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.View`
  width: 90%;
  border-radius: 8px;
  background-color: ${Colors.modalBackground};
  padding: 30px;
  align-items: center;
  shadow-color: #000;
  shadow-offset: 0px 2px;
  shadow-opacity: 0.25;
  shadow-radius: 2px;
  elevation: 5;
`;

export const ModalTitle = styled.Text`
  margin-bottom: 20px;
  color: ${Colors.black};
  font-family: Muli_700Bold;
  font-size: 20px;
`;

export const ModalText = styled.Text`
  margin-bottom: 30px;
  font-family: Muli_700Bold;
  font-size: 15px;
  color: ${Colors.darkGray};
  text-align: justify;
`;

export const ModalButtonContainer = styled.View`
  flex-direction: row;
`;

export const ModalButton = styled.View`
  flex: 1;
`;
