import { differenceInMonths, addWeeks } from "date-fns";

// Calculates the child age in months
export const getMonthsDiff = (birth_date: Date, gestational_age?: number, until?: Date) => {
  let monthsDiff: number = differenceInMonths(until ?? new Date(), new Date(birth_date));

  if (monthsDiff < 24 && gestational_age && gestational_age < 37) {
    const gestationalDiff = 40 - gestational_age;

    const gestationalBirthDate = addWeeks(
      new Date(birth_date),
      gestationalDiff
    );

    monthsDiff = differenceInMonths(until ?? new Date(), gestationalBirthDate);

    monthsDiff = monthsDiff >= 0 ? monthsDiff : 0;
  }

  return monthsDiff;
};

export const findFormMonth = (month: number) => {
  const forms = [
    { form: 2, minAge: 1, maxAge: 3 },
    { form: 4, minAge: 4, maxAge: 5 },
    { form: 6, minAge: 6, maxAge: 8 },
    { form: 9, minAge: 9, maxAge: 11 },
    { form: 12, minAge: 12, maxAge: 14 },
    { form: 15, minAge: 15, maxAge: 17 },
    { form: 18, minAge: 18, maxAge: 22 },
    { form: 24, minAge: 23, maxAge: 28 },
    { form: 30, minAge: 29, maxAge: 34 },
    { form: 36, minAge: 35, maxAge: 46 },
    { form: 48, minAge: 47, maxAge: 58 },
    { form: 60, minAge: 59, maxAge: 65 },
  ];

  const index = forms.findIndex(object => object.form === month);
  if (index !== -1) return forms[index].form;

  let monthIndex = 0;
  forms.forEach((object, i) => {
    if (month >= object.minAge && month <= object.maxAge) monthIndex = i;
  });

  return forms[monthIndex].form;
};

export const getPassingScore = (month: number) => {
  const scores = {
    twelve: [47, 48, 49, 50, 59, 60, 61, 62], // 8
    thirteen: [
      4, 15, 18, 19, 20, 29, 30, 35, 36, 37, 38, 39, 51, 52, 53, 54, 55, 56, 57,
      58, 63, 64, 65,
    ], // 23
    fourteen: [
      1, 2, 3, 6, 7, 9, 10, 12, 13, 14, 16, 17, 21, 22, 23, 24, 25, 31, 32, 33,
      34, 40, 41, 42, 43, 44, 45, 46,
    ], // 28
    fifteen: [5, 11, 26, 27, 28], // 6
    sixteen: [8], // 1
  };

  if (scores.twelve.includes(month)) return 12;
  if (scores.thirteen.includes(month)) return 13;
  if (scores.fourteen.includes(month)) return 14;
  if (scores.fifteen.includes(month)) return 15;
  if (scores.sixteen.includes(month)) return 16;

  return 14;
};
