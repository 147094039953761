import React, { createRef, useRef } from "react";
import { Text } from "react-native";

import * as Linking from 'expo-linking';
import { NavigationContainer } from "@react-navigation/native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NativeBaseProvider } from "native-base";

//Importa a font padrão
import {
  useFonts,
  Muli_300Light,
  Muli_400Regular,
  Muli_500Medium,
  Muli_600SemiBold,
  Muli_700Bold,
  Muli_800ExtraBold,
  Muli_900Black,
} from "@expo-google-fonts/muli";
import { Roboto_500Medium } from "@expo-google-fonts/roboto";

import { AuthProvider } from "./src/contexts/AuthContext";

import Routes from "./src/routes";

export default function App() {
  let [fontsLoaded] = useFonts({
    Muli_300Light,
    Muli_400Regular,
    Muli_500Medium,
    Muli_600SemiBold,
    Muli_700Bold,
    Muli_800ExtraBold,
    Muli_900Black,
    Roboto_500Medium,
  });

  const prefix = Linking.createURL('/');
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        onboarding: 'inicio',
        login: 'login',
        select_signup: 'selecionar-cadastro',
        signup: 'cadastrar',
        forgot_password: "recuperar_senha",
        TabsStack: {
          screens: {
            Home: 'home',
            Opcoes: 'opcoes',
          }
        },
        AddChild: "cadastrar-crianca", 
        Videos: "videos",
        Tutorial: "tutorial",
        Credits: "creditos",
        UpdateUser: "atualizar-conta",
        FormStack: {
          screens: {
            ChildDetails: "crianca/:childId",
            UpdateChild: "crianca/:childId/atualizar",
            FormStep1: "crianca/:childId/caderneta",
            FormStep2: "crianca/:childId/fatores-risco",
            FormStep3: "crianca/:childId/preocupacoes-pais",
            FormMain: "crianca/:childId/swyc/:formId",
            FormQuestionary: "crianca/:childId/swyc/:formId/questionario",
            DecisionGuide: "crianca/:childId/swyc/:formId/decisoes",
          }
        },
      }
    }
  };

  if (!fontsLoaded) {
    return null;
  } else {
    return (
      <NativeBaseProvider>
        <SafeAreaProvider>
          <NavigationContainer
            fallback={<Text>Loading</Text>}>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </NavigationContainer>
        </SafeAreaProvider>
      </NativeBaseProvider>
    );
  }
}
