import React, { useContext, useState } from "react";
import {
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";

import { ChildrenContext } from "../../contexts/ChildrenContext";
import { FormContext } from "../../contexts/FormContext";

import Colors from "../../utils/Colors";

import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";

import {
  Container,
  InputContainer,
  TitleInput,
  MainTitleInput,
  RiskListContainer,
  RiskContainer,
  RiskTitle,
  ButtonsContainer,
} from "./styles";

import Preform from "../../database/jsons/pre_form.json";

interface RiskFactorProps {
  navigation: any;
}

const RiksFactors: React.FC<RiskFactorProps> = props => {
  const { selectedChild } = useContext(ChildrenContext);
  const { selectedForm, setSelectedForm } = useContext(FormContext);

  const [hasRiskFactors, setHasRiskFactors] = useState(
    (selectedChild?.forms &&
      selectedChild?.forms.length >= 1 &&
      selectedChild.forms[0].risk_factors?.includes(true)) ||
      selectedChild.gestational_age < 37
  );
  const [riskFactors, setRiskFactors] = useState(
    Preform.risk_factors.map((e, index) => {
      if (
        (selectedChild?.forms &&
          selectedChild?.forms.length >= 1 &&
          selectedChild.forms[0].risk_factors &&
          selectedChild.forms[0].risk_factors[index]) ||
        (selectedChild.gestational_age < 37 && index === 5)
      ) {
        return true;
      }
      return false;
    })
  );

  const handleCheckBox = (index: number) => {
    if (index === 5) return;

    const list = [...riskFactors];

    list[index] = !list[index];

    setRiskFactors(list);
    if (list[index]) setHasRiskFactors(true);
    else if (!list.find(item => item)) setHasRiskFactors(false);
  };

  const handleSubmit = async () => {
    if (selectedChild) {
      const object = { ...selectedForm };
      object.risk_factors = hasRiskFactors
        ? riskFactors
        : riskFactors.map(() => false);

      setSelectedForm(object);
    }

    props.navigation.navigate("FormStep3");
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView contentContainerStyle={{ flexGrow: 1, paddingTop: 10 }}>
          <Container>
            <InputContainer>
              {/* <TitleInput marginTop="0px">
                A criança apresenta fatores de risco para alterações de
                desenvolvimento e comportamento?
              </TitleInput>
              <DropDownComponent
                defaultValue={hasRiskFactors?.toString()}
                items={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                zIndex={9999}
                onChangeItem={(item: { value: string }) =>
                  setHasRiskFactors(item.value === "true")
                }
              /> */}
              <MainTitleInput>
                Selecione os fatores de risco que a criança apresenta:
              </MainTitleInput>
              <RiskListContainer>
                {Preform.risk_factors.map((risk, index) => (
                  <TouchableOpacity
                    key={index}
                    style={{ flex: 1, marginBottom: 40 }}
                    onPress={() => handleCheckBox(index)}>
                    <RiskContainer>
                      <Checkbox
                        wrapperRadius="4px"
                        innerRadius="2px"
                        color={!hasRiskFactors ? Colors.darkGray : null}
                        selected={riskFactors[index]}
                      />
                      <RiskTitle>{risk.question}</RiskTitle>
                    </RiskContainer>
                  </TouchableOpacity>
                ))}
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => {
                    if (selectedChild.gestational_age < 37) return;
                    if (!hasRiskFactors) return;

                    setRiskFactors(
                      Preform.risk_factors.map((e, index) => {
                        return false;
                      })
                    );
                    setHasRiskFactors(!hasRiskFactors);
                  }}>
                  <RiskContainer>
                    <Checkbox
                      wrapperRadius="4px"
                      innerRadius="2px"
                      color={hasRiskFactors ? Colors.darkGray : null}
                      selected={hasRiskFactors !== true}
                    />
                    <RiskTitle>
                      A criança não apresenta fatores de risco
                    </RiskTitle>
                  </RiskContainer>
                </TouchableOpacity>
              </RiskListContainer>
            </InputContainer>
            <ButtonsContainer>
              <Button text="Avançar" onPress={handleSubmit} />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default RiksFactors;
