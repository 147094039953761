import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  flex-direction: column;
  background-color: white;
  align-items: center;
  padding: 30px;
  padding-top: 10px;
`;

export const LogoContainer = styled.View`
  height: 30%;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;
