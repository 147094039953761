import styled from "styled-components/native";
import Colors from "../../utils/Colors";

export const Container = styled.View`
  flex: 1;
  padding: 0px 30px;
  background-color: ${Colors.white};
`;

export const InputContainer = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

interface TitleInputProps {
  marginTop?: string;
}

export const TitleInput = styled.Text<TitleInputProps>`
    font-family: Muli_400Regular;
    font-size: 11px;
    color: #A3A3A3;
    flex-shrink: 1;
    margin-top: ${(props: TitleInputProps) => props.marginTop ?? "10px"}
    margin-bottom: 10px;
`;

export const ButtonsContainer = styled.View`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;
