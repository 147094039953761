import React, { useContext, useState } from "react";
import {
  View,
  ScrollView,
  Image,
  KeyboardAvoidingView,
  Platform,
  useWindowDimensions,
} from "react-native";

import { CommonActions } from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Toast } from "native-base";

import { ChildrenContext } from "../../contexts/ChildrenContext";
import { FormContext } from "../../contexts/FormContext";

import Colors from "../../utils/Colors";

import Button from "../../components/Button";
import InputError from "../../components/InputError";
import DropDownComponent from "../../components/DropDown";

import {
  Container,
  InputContainer,
  TitleInput,
  MainTitleInput,
  ButtonsContainer,
  SwycContainer,
} from "./styles";

interface RiskFactorProps {
  navigation: any;
}

const RiksFactors: React.FC<RiskFactorProps> = props => {
  const { width, height } = useWindowDimensions();

  const { selectedChild } = useContext(ChildrenContext);
  const { isFormLoading, selectedForm, setSelectedForm, createForm } =
    useContext(FormContext);

  const [hasDevelopmentConcern, setHasDevelopmentConcern] = useState(
    selectedChild?.forms &&
      selectedChild?.forms.length >= 1 &&
      selectedChild?.forms[0].parents_concerns
      ? selectedChild?.forms[0].parents_concerns[0]
      : ""
  );
  const [hasBehaviourConcern, setHasBehaviourConcern] = useState(
    selectedChild?.forms &&
      selectedChild?.forms.length >= 1 &&
      selectedChild?.forms[0].parents_concerns
      ? selectedChild?.forms[0].parents_concerns[1]
      : ""
  );

  const [hasDevelopmentConcernError, setHasDevelopmentConcernError] =
    useState("");
  const [hasBehaviourConcernError, setHasBehaviourConcernError] = useState("");

  const handleError = () => {
    if (hasDevelopmentConcern === "") {
      setHasDevelopmentConcernError("Selecione uma opção");
      return true;
    }
    setHasDevelopmentConcernError("");
    if (hasBehaviourConcern === "") {
      setHasBehaviourConcernError("Selecione uma opção");
      return true;
    }
    setHasBehaviourConcernError("");

    return false;
  };

  const handleSubmit = async () => {
    if (handleError() || isFormLoading) return;

    const object = { ...selectedForm };
    object.parents_concerns = [hasDevelopmentConcern, hasBehaviourConcern];
    setSelectedForm(object);

    const response = await createForm(object);

    if (response && response.status === 201) {
      setSelectedForm(response.data.form);

      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: "ChildDetails" }, { name: "FormMain" }],
        })
      );
    } else {
      Toast.show({
        title: response.data.error.message,
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <Container>
          <ScrollView contentContainerStyle={{ flexGrow: 1, paddingTop: 10 }}>
            <InputContainer>
              <MainTitleInput>
                Com relação ao comportamento atual da sua criança:
              </MainTitleInput>
              <TitleInput>
                Você tem alguma preocupação com o aprendizado ou com o
                desenvolvimento de sua criança?
              </TitleInput>
              <DropDownComponent
                defaultValue={hasDevelopmentConcern}
                items={[
                  {
                    label: "Não",
                    value: "Não",
                  },
                  {
                    label: "Um pouco",
                    value: "Um pouco",
                  },
                  {
                    label: "Muito",
                    value: "Muito",
                  },
                ]}
                zIndex={9999}
                onChangeItem={(item: { value: string }) =>
                  setHasDevelopmentConcern(item.value)
                }
              />
              {hasDevelopmentConcernError !== "" && (
                <InputError text={hasDevelopmentConcernError} />
              )}
              <TitleInput>
                Você tem alguma preocupação com o comportamento de sua criança?
              </TitleInput>
              <DropDownComponent
                defaultValue={hasBehaviourConcern}
                items={[
                  {
                    label: "Não",
                    value: "Não",
                  },
                  {
                    label: "Um pouco",
                    value: "Um pouco",
                  },
                  {
                    label: "Muito",
                    value: "Muito",
                  },
                ]}
                zIndex={9998}
                onChangeItem={(item: { value: string }) =>
                  setHasBehaviourConcern(item.value)
                }
              />
              {hasBehaviourConcernError !== "" && (
                <InputError text={hasBehaviourConcernError} />
              )}
            </InputContainer>

            <SwycContainer>
              {/* <SwycLogo width="100%" /> */}
              <Image
                style={{ width: 125, height: 125 }}
                source={require("../../../assets/swyc_logo.jpg")}
              />
            </SwycContainer>

            <ButtonsContainer>
              <Button
                text="Avançar"
                loading={isFormLoading}
                onPress={handleSubmit}
              />
            </ButtonsContainer>
          </ScrollView>
        </Container>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default RiksFactors;
