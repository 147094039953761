import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  useWindowDimensions,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import { IModalListInDto } from "react-native-picker-modal-view/dist/Interfaces";
import { Toast } from "native-base";
import Icon from "react-native-vector-icons/Feather";

import { AuthContext } from "../../contexts/AuthContext";
import { ChildrenContext } from "../../contexts/ChildrenContext";

import { Child } from "../../database/Models";

import States from "../../services/states";
import { findByStateName } from "../../services/cities";
import Colors, { cardColors } from "../../utils/Colors";

import Input from "../../components/Input";
import InputError from "../../components/InputError";
import DropDownComponent from "../../components/DropDown";
import Button from "../../components/Button";

import {
  Container,
  InputContainer,
  TitleInput,
  CustomSelect,
  Picker,
  ButtonsContainer,
} from "./styles";

interface PickerProps {
  Name: string;
  Value: string;
  Code: string;
  Id: number;
}

interface AddChildProps {
  navigation: any;
}

const AddChild: React.FC<AddChildProps> = props => {
  const { width } = useWindowDimensions();

  const { user } = useContext(AuthContext)
  const { isChildrenLoading, childArray, createChild, setSelectedChild } =
    useContext(ChildrenContext);

  const motherNameInputRef: React.RefObject<TextInput> = useRef(null);

  const dropdownStates = States.map(function (state) {
    return {
      Name: state.nome,
      Value: state.nome,
      Code: state.sigla,
      Id: state.id,
    };
  });
  const [dropdownCities, setDropdownCities] = useState<PickerProps[]>([]);

  const [initialState, setInitialState] = useState<IModalListInDto>()
  const [initialCity, setInitialCity] = useState<IModalListInDto>()

  const [name, setName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [gender, setGender] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gestationalAge, setGestationalAge] = useState("");
  const [atPublicService, setAtPublicService] = useState("");
  const [state, setState] = useState(user?.state);
  const [city, setCity] = useState(user?.city);

  const [nameError, setNameError] = useState("");
  const [motherNameError, setMotherNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColorError, setSkinColorError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [gestationalAgeError, setGestationalAgeError] = useState("");
  const [atPublicServiceError, setAtPublicServiceError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");

  const handleState = (item: IModalListInDto) => {
    setState(item.Value.toString());
    
    if (item.Value.toString() != state) {
      setCity(undefined);
    }

    const cities = findByStateName(item.Value.toString());

    if (cities) {
      const newDropdownCities: PickerProps[] = cities.map(city => {
        return {
          Name: city.nome,
          Value: city.nome,
          Code: city.nome,
          Id: city.id,
        };
      });

      setDropdownCities(newDropdownCities);
    }

    return item;
  };

  const handleSubmit = async () => {
    // Set and unset errors on user submit
    if (name.trim() === "") {
      setNameError("Preencha o campo nome");
      return;
    }
    setNameError("");
    if (motherName.trim() === "") {
      setMotherNameError("Preencha o campo nome da mãe");
      return;
    }
    setMotherNameError("");
    if (gender === "") {
      setGenderError("Selecione uma opção no campo gênero");
      return;
    }
    setGenderError("");
    if (skinColor === "") {
      setSkinColorError("Selecione uma opção no campo cor ou raça");
      return;
    }
    setSkinColorError("");
    if (birthdate.length < 10) {
      setBirthdateError("Digite uma data válida");
      return;
    }
    setBirthdateError("");
    if (gestationalAge === "") {
      setGestationalAgeError("Preencha o campo idade gestacional");
      return;
    }
    setGestationalAgeError("");
    if (atPublicService === "") {
      setAtPublicServiceError("Selecione uma opção no campo serviço público");
      return;
    }
    setAtPublicServiceError("");
    if (atPublicService == "true") {
      if (state == "" || state == null) {
        setStateError("Selecione uma opção no campo estado");
        return;
      }
      setStateError("");
      if (city == "" || city == null) {
        setCityError("Selecione uma opção no campo cidade");
        return;
      }
      setCityError("");
    }

    // Split date to later convert to yyyy/mm/gestationalAge.substr(0, gestationalAge.indexOf(' '))yy
    const birthdateParts = birthdate.split("/");

    const child: Child = {
      name: name.trim(),
      mother_name: motherName.trim(),
      gender,
      skin_color: skinColor,
      birth_date: new Date(
        +parseInt(birthdateParts[2]),
        parseInt(birthdateParts[1]) - 1,
        +parseInt(birthdateParts[0])
      ),
      gestational_age: parseInt(gestationalAge),
      at_public_service: atPublicService == "true",
      state: atPublicService == "true" ? state : undefined,
      city: atPublicService == "true" ? city : undefined,
    };

    const response = await createChild(child);

    if (response && response.status === 201) {
      setSelectedChild(response.data.child);
      props.navigation.replace("FormStack", {
        screen: "ChildDetails",
        params: {
          color: cardColors[childArray.length % 4],
        },
      });
    } else {
      Toast.show({
        title: response
          ? response.data.error.message
          : "Não foi possível avançar. Verifique sua conexão e tente novamente",
        duration: 1500,
        isClosable: true,
        placement: width <= 1024 ? "bottom" : "bottom-left",
        padding: 4,
        marginLeft: width <= 1024 ? 0 : 20,
        status: "error",
        variant: "left-accent",
      });
    }
  };

  useEffect(() => {
    const selectedState = States.find(state => state.nome == user?.state)
    if (selectedState !== undefined) {
      const item = {
        Id: selectedState.id,
        Name: selectedState.nome,
        Value: selectedState.nome
      }
      setInitialState(item)
      handleState(item)

      const cities = findByStateName(selectedState.nome);
      const selectedCity = cities.find(city => city.nome == user?.city)
      if (selectedCity !== undefined) {
        setInitialCity({
          Id: selectedCity.id,
          Name: selectedCity.nome,
          Value: selectedCity.nome
        })
      }
    }
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.white }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Container>
            <InputContainer>
              <TitleInput>Nome da criança</TitleInput>
              <Input
                placeholder="Digite o nome da criança"
                keyboard="default"
                return="next"
                onChangeText={value => setName(value)}
                value={name}
                onSubmit={() =>
                  motherNameInputRef.current &&
                  motherNameInputRef.current.focus()
                }
              />
              {nameError !== "" && <InputError text={nameError} />}
              <TitleInput>Nome da mãe</TitleInput>
              <Input
                inputRef={motherNameInputRef}
                keyboard="default"
                return="next"
                placeholder="Digite o nome da mãe da criança"
                value={motherName}
                onChangeText={text => setMotherName(text)}
                blurOnSubmit={true}
                onSubmit={() => { }}
              />
              {motherNameError !== "" && <InputError text={motherNameError} />}
              <TitleInput>Gênero</TitleInput>
              <DropDownComponent
                items={[
                  { label: "Masculino", value: "male" },
                  { label: "Feminino", value: "female" },
                  { label: "Outro", value: "other" },
                ]}
                zIndex={9000}
                onChangeItem={(item: { value: string }) =>
                  setGender(item.value)
                }
              />
              {genderError !== "" && <InputError text={genderError} />}
              <TitleInput>Cor ou raça</TitleInput>
              <DropDownComponent
                items={[
                  { label: "Branca", value: "branca" },
                  { label: "Preta", value: "preta" },
                  { label: "Amarela", value: "amarela" },
                  { label: "Parda", value: "parda" },
                  { label: "Indígena", value: "indigena" },
                  { label: "Sem declaração", value: "sem declaracao" },
                ]}
                zIndex={8000}
                onChangeItem={(item: { value: string }) =>
                  setSkinColor(item.value)
                }
              />
              {skinColorError !== "" && <InputError text={skinColorError} />}
              <TitleInput>Data de nascimento</TitleInput>
              <Input
                mask="datetime"
                keyboard="numeric"
                return="next"
                placeholder="dd/mm/aaaa"
                value={birthdate}
                onChangeText={text => setBirthdate(text)}
                onSubmit={() => { }}
                blurOnSubmit={true}
              />
              {birthdateError !== "" && <InputError text={birthdateError} />}
              <TitleInput>Idade Gestacional (em semanas) </TitleInput>
              <DropDownComponent
                items={Array.from(Array(22), (_, i) => {
                  return {
                    label: (44 - i).toString(),
                    value: (44 - i).toString(),
                  };
                })}
                zIndex={7000}
                onChangeItem={(item: { value: string }) =>
                  setGestationalAge(item.value)
                }
              />
              {gestationalAgeError !== "" && (
                <InputError text={gestationalAgeError} />
              )}
              <TitleInput>Esta criança está sendo atendida em um serviço público de saúde?</TitleInput>
              <DropDownComponent
                items={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                zIndex={6000}
                onChangeItem={(item: { value: string }) =>
                  setAtPublicService(item.value)
                }
              />
              {atPublicServiceError !== "" && (
                <InputError text={atPublicServiceError} />
              )}
              {atPublicService == "true" && (
                <View>
                  <TitleInput>Estado</TitleInput>
                  <Picker
                    renderSelectView={(
                      __: any,
                      selected: IModalListInDto,
                      showModal: () => void
                    ) => (
                      <TouchableOpacity
                        onPress={showModal}
                        style={{ height: 48.7 }}>
                        <CustomSelect style={{ height: 48.7 }}>
                          <Text
                            style={{
                              fontFamily: "Muli_400Regular",
                              textAlign: "left",
                              color: selected.Name || state ? "#454E4B" : "#bbb",
                            }}>
                            {selected.Name ?? state ?? "Selecione uma opção"}
                          </Text>
                          <Icon
                            name="chevron-down"
                            size={20}
                            color={Colors.mediumGray}
                          />
                        </CustomSelect>
                      </TouchableOpacity>
                    )}
                    onClosed={() => { }}
                    onEndReached={() => { }}
                    items={dropdownStates}
                    showAlphabeticalIndex={false}
                    showToTopButton={false}
                    searchPlaceholderText={"Pesquise seu estado..."}
                    selected={initialState}
                    onSelected={handleState}
                  />
                  {stateError !== "" && <InputError text={stateError} />}
                  <TitleInput>Cidade</TitleInput>
                  <Picker
                    renderSelectView={(
                      __: any,
                      selected: IModalListInDto,
                      showModal: () => void
                    ) => (
                      <TouchableOpacity
                        onPress={showModal}
                        style={{ height: 48.7 }}>
                        <CustomSelect style={{ height: 48.7 }}>
                          <Text
                            style={{
                              fontFamily: "Muli_400Regular",
                              textAlign: "left",
                              color: selected.Name || city ? "#454E4B" : "#bbb",
                            }}>
                            {selected.Name ?? city ?? "Selecione uma opção"}
                          </Text>
                          <Icon
                            name="chevron-down"
                            size={20}
                            color={Colors.mediumGray}
                          />
                        </CustomSelect>
                      </TouchableOpacity>
                    )}
                    items={dropdownCities}
                    removeClippedSubviews={true}
                    showAlphabeticalIndex={false}
                    showToTopButton={false}
                    searchPlaceholderText={"Pesquise sua cidade..."}
                    selected={initialCity}
                    onSelected={(item: IModalListInDto) => {
                      setCity(item.Value.toString());
                      return item;
                    }}
                    onClosed={() => { }}
                    onEndReached={() => { }}
                  />
                  {cityError !== "" && <InputError text={cityError} />}
                </View>
              )}
            </InputContainer>
            <ButtonsContainer>
              <Button
                loading={isChildrenLoading}
                text="Cadastrar criança"
                onPress={handleSubmit}
              />
            </ButtonsContainer>
          </Container>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default AddChild;
