import React, { useState, useEffect, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Location from "expo-location";

import * as childService from "../services/child";

import { Child } from "../database/Models";

interface ContextProps {
  childArray: Child[];
  setChildArray: any;
  selectedChild: Child;
  setSelectedChild: any;
  isChildrenLoading: boolean;
  loadChildren: any;
  createChild: any;
  updateChild: any;
  deleteChild: any;
}

export const ChildrenContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const ChildrenProvider = ({ children }: any) => {
  const [childArray, setChildArray] = useState<Child[]>([]);
  const [selectedChild, setSelectedChild] = useState<Child>({} as Child);

  const [isChildrenLoading, setIsChildrenLoading] = useState(false);

  const initialChildrenRender = useRef(true);
  const initialSelectedChildRender = useRef(true);

  async function createChild(child: Child) {
    if (!isChildrenLoading) {
      setIsChildrenLoading(true);

      const { status } = await Location.getPermissionsAsync();
      
      if (status === "granted") {
        const location = await Location.getLastKnownPositionAsync({});

        if (location) {
          child.coordinates =
            location.coords.latitude.toString() +
            " " +
            location.coords.longitude.toString();
        }
      }

      const response = await childService.createChild(child);

      if (response && response.status == 201) {
        setChildArray([...childArray, response.data.child]);
      }

      setIsChildrenLoading(false);

      return response;
    }
  }

  async function updateChild(child: Child) {
    if (!isChildrenLoading) {
      setIsChildrenLoading(true);

      const response = await childService.updateChild(child);
      if (response && response.status == 200) {
        const array = [...childArray];
        const index = array.findIndex(element => element.id === child.id);

        if (index !== -1) {
          array[index] = Object.assign(array[index], child);
          setChildArray(array);
        }

        if (selectedChild && selectedChild.id === child.id) {
          setSelectedChild(Object.assign(selectedChild, child));
        }
      }

      setIsChildrenLoading(false);

      return response;
    }
  }

  async function deleteChild(id: number) {
    if (!isChildrenLoading) {
      setIsChildrenLoading(true);

      const response = await childService.deleteChild(id);

      if (response && response.status == 200) {
        const array = [...childArray];
        const index = array.findIndex(element => element.id === id);

        if (index !== -1) {
          array.splice(index, 1);
          setChildArray(array);
        }
      }

      setIsChildrenLoading(false);

      return response;
    }
  }

  async function loadChildren() {
    setIsChildrenLoading(true);

    const response = await childService.getChildren();

    if (response && response.status === 200) {
      setChildArray(response.data.children);
    } else {
      const localChildren = await AsyncStorage.getItem("@TediApp:children");

      if (localChildren) setChildArray(JSON.parse(localChildren));
    }

    setIsChildrenLoading(false);

    return response;
  };

  async function loadSelectedChild() {
    const localSelectedChild = await AsyncStorage.getItem("@TediApp:selectedChild");
    if (localSelectedChild) setSelectedChild(JSON.parse(localSelectedChild));
  }

  useEffect(() => {
    if (childArray && !initialChildrenRender.current) {
      AsyncStorage.setItem("@TediApp:children", JSON.stringify(childArray));
    } else {
      loadChildren();
      initialChildrenRender.current = false;
    }
  }, [childArray]);

  useEffect(() => {
    if (selectedChild !== ({} as Child) && !initialSelectedChildRender.current) {
      AsyncStorage.setItem("@TediApp:selectedChild", JSON.stringify(selectedChild));
    } else {
      loadSelectedChild()
      initialSelectedChildRender.current = false;
    }
  }, [selectedChild])

  return (
    <ChildrenContext.Provider
      value={{
        childArray,
        setChildArray,
        selectedChild,
        setSelectedChild,
        isChildrenLoading,
        loadChildren,
        createChild,
        updateChild,
        deleteChild,
      }}>
      {children}
    </ChildrenContext.Provider>
  );
};
