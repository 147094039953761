import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  Pressable,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Modal,
  useWindowDimensions,
  Share,
  Platform,
} from "react-native";

import { SafeAreaView } from "react-native-safe-area-context";
import { activateKeepAwake, deactivateKeepAwake } from "expo-keep-awake";
import * as ScreenOrientation from "expo-screen-orientation";
import * as Clipboard from "expo-clipboard";
import { FontAwesome5 } from "@expo/vector-icons";
import { AVPlaybackStatus, Video, VideoFullscreenUpdateEvent } from "expo-av";
import { Toast } from "native-base";

import { baseURL } from "../../services/api";

import GuideJson from "../../database/jsons/decision_guide.json";

import {
  Container,
  IndicatorContainer,
  Indicator,
  DecisionGuideCard,
  DecisionGuideCardLink,
  DecisionGuideCardText,
  ButtonsContainer,
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalButtonContainer,
  ModalButton,
  VideoCard,
  CardsContainer,
  ModalHeader,
} from "./styles";

import Button from "../../components/Button";
import Colors from "../../utils/Colors";
import { getMonthsDiff } from "../../utils/Functions";
import { FormContext } from "../../contexts/FormContext";
import { ChildrenContext } from "../../contexts/ChildrenContext";

interface DecisionGuideProps {
  route: {
    params: {
      stage: string;
      result: number | string;
    };
  };
  navigation: any;
}

interface ActionType {
  index: number;
  text: string;
  multiple?: boolean;
}

interface IndicatorType {
  index: number;
  color: string;
}

const DecisionGuide: React.FC<DecisionGuideProps> = props => {
  const { width: screenWidth } = useWindowDimensions();
  const { selectedForm } = useContext(FormContext);
  const { selectedChild } = useContext(ChildrenContext);

  const [guide, setGuide] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const { stage, result } = props.route.params;

  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const videoRef = useRef<Video | null>(null);
  const [videoStatus, setVideoStatus] = useState<AVPlaybackStatus>(
    {} as AVPlaybackStatus
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFullscreenUpdate = async ({
    fullscreenUpdate,
  }: VideoFullscreenUpdateEvent) => {
    switch (fullscreenUpdate) {
      case Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT:
        await ScreenOrientation.unlockAsync(); // only on Android required
        break;
      case Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS:
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        ); // only on Android required
        break;
    }
  };

  const handleStatus = (value: any) => {
    if (value.isPlaying) activateKeepAwake();
    else deactivateKeepAwake();
  };

  const getIndicators = () => {
    const indicators = guide.indicators.map(
      (indicator: IndicatorType, index: number) => {

        let active = result === indicator.index;

        if (typeof result === "number" && result % 1 !== 0) {
          active = Math.trunc(result) === indicator.index;
        }

        if (stage === "family_questions" && typeof result === "string") {
          if (parseInt(result) > 0) active = 1 === indicator.index;
          else active = 0 === indicator.index;
        }

        return (
          <Indicator key={index} color={indicator.color} active={active} />
        );
      }
    );

    return indicators;
  };

  const getActions = () => {
    const actions = guide.actions
      .filter((action: ActionType) => {
        let index = result;
        if (stage === "family_questions" && typeof result === "string") {
          if (parseInt(result) > 0) index = 1;
          else index = 0;
        }

        return action.index === index;
      })
      .map((action: ActionType, index: number) => {
        if (
          stage === "family_questions" &&
          result !== undefined &&
          result[index] == "0" &&
          action.index === 1
        )
          return null;

        return (
          <DecisionGuideCard key={index}>
            {loadText(action.text)}
          </DecisionGuideCard>
        );
      });

    return actions;
  };

  const getVideo = () => {
    // Uri's list
    const videos = [
      {
        uri: "video-cuidando-aprendendo",
        youtube_url: "https://www.youtube.com/watch?v=GWuMbOcwTg0",
        youtube_title: "Confira o vídeo Cuidando e Aprendendo do TEDI",
        minAge: -1,
        maxAge: -1,
      },
      {
        uri: "video-1-3-months",
        youtube_url: "https://www.youtube.com/watch?v=vQvxx2qY_Hk",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 1 a 3 meses",
        minAge: 1,
        maxAge: 3,
      },
      {
        uri: "video-4-5-months",
        youtube_url: "https://www.youtube.com/watch?v=dtLdPphblqY",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 4 a 5 meses",
        minAge: 4,
        maxAge: 5,
      },
      {
        uri: "video-6-8-months",
        youtube_url: "https://www.youtube.com/watch?v=OFvNkLZS-yI",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 6 a 8 meses",
        minAge: 6,
        maxAge: 8,
      },
      {
        uri: "video-9-11-months",
        youtube_url: "https://www.youtube.com/watch?v=_cq_kPDawNI",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 9 a 11 meses",
        minAge: 9,
        maxAge: 11,
      },
      {
        uri: "video-12-17-months",
        youtube_url: "https://www.youtube.com/watch?v=Gy9-Nek97gA",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 12 a 17 meses",
        minAge: 12,
        maxAge: 17,
      },
      {
        uri: "video-18-22-months",
        youtube_url: "https://www.youtube.com/watch?v=bbhzC4lPbfQ",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 18 a 22 meses",
        minAge: 18,
        maxAge: 22,
      },
      {
        uri: "video-23-28-months",
        youtube_url: "https://www.youtube.com/watch?v=vnDTWq3YzIo",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 23 a 28 meses",
        minAge: 23,
        maxAge: 28,
      },
      {
        uri: "video-29-34-months",
        youtube_url: "https://www.youtube.com/watch?v=nPAeQsntfyo",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 29 a 34 meses",
        minAge: 29,
        maxAge: 34,
      },
      {
        uri: "video-35-40-months",
        youtube_url: "https://www.youtube.com/watch?v=5BSrYp53f8Q",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 35 a 40 meses",
        minAge: 35,
        maxAge: 40,
      },
      {
        uri: "video-41-46-months",
        youtube_url: "https://www.youtube.com/watch?v=6Lgdx6GaeKw",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 41 a 46 meses",
        minAge: 41,
        maxAge: 46,
      },
      {
        uri: "video-47-52-months",
        youtube_url: "https://www.youtube.com/watch?v=UVCxBm_tuPs",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 47 a 52 meses",
        minAge: 47,
        maxAge: 52,
      },
      {
        uri: "video-53-58-months",
        youtube_url: "https://www.youtube.com/watch?v=-rBQ6EfqrWc",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 53 a 58 meses",
        minAge: 53,
        maxAge: 58,
      },
      {
        uri: "video-59-65-months",
        youtube_url: "https://www.youtube.com/watch?v=swskQT-Jnxo",
        youtube_title: "Confira o vídeo de estímulos do TEDI para crianças de 59 a 65 meses",
        minAge: 59,
        maxAge: 65,
      },
    ];

    let index = 0;

    if (selectedForm && stage !== "bpsc" && stage !== "ppsc") {
      // Get form and child current age in months to compare
      const formAge = getMonthsDiff(selectedForm.date);
      const childAge = getMonthsDiff(
        selectedChild.birth_date,
        selectedChild.gestational_age
      );

      // Child's age at the day the form was created
      const diff = childAge - formAge;

      videos.forEach((object, i) => {
        if (diff >= object.minAge && diff <= object.maxAge) index = i;
      });
    }

    return videos[index];
  };

  const loadText = (text: string) => {
    const markupStart = text.search("<b>");

    if (markupStart !== -1)
      return (
        <DecisionGuideCardText style={{ fontWeight: "bold" }}>
          {text.replace(/<b>|<\/b>/g, "")}
        </DecisionGuideCardText>
      );

    return <DecisionGuideCardText>{text}</DecisionGuideCardText>;
  };

  useEffect(() => {
    switch (stage) {
      case "developmental_milestones":
        setGuide(GuideJson.developmental_milestones);
        break;
      case "bpsc":
        setGuide(GuideJson.bpsc);
        break;
      case "ppsc":
        setGuide(GuideJson.ppsc);
        break;
      case "posi":
        setGuide(GuideJson.posi);
        break;
      case "family_questions":
        setGuide(GuideJson.family_questions);
        break;
      case "epds":
        setGuide(GuideJson.epds);
        break;

      default:
        setGuide(GuideJson.family_questions);
        break;
    }
    setIsLoading(false);
  }, []);

  return isLoading ? null : (
    <SafeAreaView style={{ flex: 1, paddingBottom: 20 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <IndicatorContainer>{getIndicators()}</IndicatorContainer>
          <CardsContainer>
            {getActions()}
            {stage === "family_questions" &&
              typeof result === "string" &&
              parseInt(result.substring(0, 5)) > 0 && (
                <DecisionGuideCard>
                  <DecisionGuideCardText>
                    Verifique os recursos assistenciais disponíveis na sua região
                    para ajudar a família a lidar com os fatores de risco
                    identificados
                  </DecisionGuideCardText>
                </DecisionGuideCard>
              )}
            {stage === "developmental_milestones" && result !== 3 && (
              <DecisionGuideCardLink onPress={() => setIsModalVisible(true)}>
                <DecisionGuideCardText
                  style={{ textDecorationLine: "underline" }}>
                  Oriente os pais sobre estimulação (clique para ver o video)
                </DecisionGuideCardText>
              </DecisionGuideCardLink>
            )}
           {(stage === "bpsc" || stage === "ppsc") && (
              <DecisionGuideCardLink onPress={() => setIsModalVisible(true)}>
                <DecisionGuideCardText
                  style={{ textDecorationLine: "underline" }}>
                  Mostre o vídeo Cuidando e Aprendendo
                </DecisionGuideCardText>
              </DecisionGuideCardLink>
            )}
            <View style={{ flexGrow: 1 }} />
            <ButtonsContainer>
              <Button
                text="Avançar"
                onPress={() => props.navigation.navigate("FormMain")}
              />
            </ButtonsContainer>
          </CardsContainer>
        </Container>
      </ScrollView>
      {/* <View style={{ height: 20 }} /> */}
      <ModalContainer>
        <Modal
          key={"sd1"}
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          statusBarTranslucent={true}
          onRequestClose={() => {
            setIsModalVisible(!isModalVisible);
          }}>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Vídeo de estímulos</ModalTitle>
                <TouchableOpacity
                  style={{ right: -15 }}
                  onPress={() => {
                    Clipboard.default.setString(
                      `${getVideo().youtube_title} : \n${getVideo().youtube_url
                      }`
                    );

                    Toast.show({
                      title: "Link copiado para a área de transferência",
                      duration: 1500,
                      isClosable: true,
                      placement: screenWidth <= 1024 ? "bottom" : "bottom-left",
                      padding: 4,
                      marginLeft: screenWidth <= 1024 ? 0 : 20,
                    });
                  }}>
                  <FontAwesome5 name="share-alt" color="#007aff" size={18} />
                </TouchableOpacity>
              </ModalHeader>
              <VideoCard>
                <TouchableWithoutFeedback
                  onPress={async () => {
                    if (videoRef.current) await videoRef.current.pauseAsync();
                  }}>
                  <Video
                    key={"3ed2"}
                    ref={component => (videoRef.current = component)}
                    source={{
                      uri: baseURL + "/video/" + getVideo().uri,
                    }}
                    style={{
                      width: "100%",
                      height: (screenWidth * 0.74 * 9) / 16,
                    }}
                    usePoster={true}
                    posterSource={{
                      uri: baseURL + "/video/thumb/" + getVideo().uri,
                      cache: "reload",
                    }}
                    posterStyle={{
                      resizeMode: "stretch",
                    }}
                    useNativeControls={true}
                    resizeMode="contain"
                    onFullscreenUpdate={onFullscreenUpdate}
                    onLayout={e => {
                      const newWidth = e.nativeEvent.layout.width;
                      const newHeight = e.nativeEvent.layout.height;

                      setVideoWidth(newWidth);
                      setVideoHeight(newHeight);
                    }}
                    onPlaybackStatusUpdate={status => {
                      setVideoStatus(status);
                      handleStatus(status);
                    }}
                  />
                </TouchableWithoutFeedback>
                {videoStatus.isLoaded &&
                  !videoStatus.isPlaying &&
                  !videoStatus.isBuffering &&
                  videoRef.current && (
                    <Pressable
                      onPress={async () => {
                        if (videoRef.current) {
                          if (videoStatus.positionMillis === videoStatus.durationMillis || !videoStatus.durationMillis || videoStatus.positionMillis === 0) { 
                            await videoRef.current.replayAsync({
                              positionMillis: 0,
                              shouldPlay: true,
                            });
                          } else {
                            await videoRef.current.playAsync();
                          }
                        }
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}>
                      <FontAwesome5
                        name="play-circle"
                        size={57}
                        color={Colors.white}
                      />
                    </Pressable>
                  )}
                {(videoStatus.isLoaded !== true ||
                  (videoStatus.isBuffering && !videoStatus.isPlaying)) && (
                    <View
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      }}>
                      <ActivityIndicator color={Colors.white} size="large" />
                    </View>
                  )}
              </VideoCard>
              <ModalButtonContainer>
                <ModalButton>
                  <Button
                    text="Fechar"
                    backgroundColor={Colors.darkGray}
                    underlayColor={Colors.black}
                    onPress={() => setIsModalVisible(false)}
                  />
                </ModalButton>
              </ModalButtonContainer>
            </ModalContent>
            {/* </View> */}
          </ModalOverlay>
        </Modal>
      </ModalContainer>
    </SafeAreaView>
  );
};

export default DecisionGuide;
