import React, { useEffect, useContext, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  TextInput,
  SafeAreaView,
  useWindowDimensions,
} from "react-native";

import * as Location from "expo-location";
import { Toast } from "native-base";

import { AuthContext } from "../../contexts/AuthContext";

import {
  Container,
  ButtonsContainer,
  InputContainer,
  TitleInput,
  ForgetPass,
  ForgetText,
  LogoContainer,
} from "./styles";

import Colors from "../../utils/Colors";

import Logo from "../../components/Logo";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputError from "../../components/InputError";

interface LoginProps {
  navigation: any;
}

const Login: React.FC<LoginProps> = props => {
  const authContext = useContext(AuthContext);

  const { height, width } = useWindowDimensions();

  const passwordInputRef: React.RefObject<TextInput> = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSignIn = async () => {
    if (email.length === 0) {
      setEmailError("Preencha o e-mail para entrar");
    } else if (password.length < 6) {
      setEmailError("");
      setPasswordError("Preencha a senha de 6 digitos ou mais para entrar");
    } else {
      setEmailError("");
      setPasswordError("");

      const response = await authContext.signIn(email, password);

      if (!response || response?.status != 200) {
        Toast.show({
          title: response 
            ? response.data.error.message 
            : "Não foi possível avançar. Verifique sua conexão e tente novamente",
          duration: 1500,
          isClosable: true,
          placement: width <= 1024 ? "bottom" : "bottom-left",
          padding: 4,
          marginLeft: width <= 1024 ? 0 : 20,
          status: "error",
          variant: "left-accent",
        });
      }
    }
  };

  useEffect(() => {
    const requestPermissions = async () => {
      const locationPermission = await Location.getPermissionsAsync();
      if (!locationPermission.granted && locationPermission.canAskAgain)
        await Location.requestPermissionsAsync();
    };

    requestPermissions();
  }, []);

  const styles = StyleSheet.create({
    wrapper: {
      height: "100%",
      width: "100%",
      paddingTop: height * 0.1,
      backgroundColor: Colors.white,
    },
  });

  return (
    <View style={styles.wrapper}>
      <SafeAreaView style={{ flex: 1 }}>
        <Container>
          <LogoContainer
            style={{
              marginBottom: 0.05 * height,
              width: width < 768 ? "70%" : "40%",
            }}>
            <Logo width="100%" height="100%" />
          </LogoContainer>

          <View style={{ flex: 1, width: width <= 1024 ? "90%" : "50%" }}>
            <ScrollView showsVerticalScrollIndicator={true}>
              <View style={{ flex: 1 }}>
                <InputContainer>
                  <TitleInput>E-mail</TitleInput>
                  <Input
                    keyboard="email-address"
                    return="next"
                    onChangeText={text => setEmail(text.trim())}
                    value={email}
                    onSubmit={() =>
                      passwordInputRef.current &&
                      passwordInputRef.current.focus()
                    }
                  />
                  {emailError ? (
                    <InputError text={emailError} />
                  ) : (
                    <View></View>
                  )}
                  <TitleInput>Senha</TitleInput>
                  <Input
                    inputRef={passwordInputRef}
                    secureTextEntry={true}
                    keyboard="default"
                    return="done"
                    blurOnSubmit={true}
                    onChangeText={text => setPassword(text)}
                    value={password}
                    onSubmit={() => {}}
                  />
                  {passwordError ? (
                    <InputError text={passwordError} />
                  ) : (
                    <View></View>
                  )}
                  <ForgetPass
                    onPress={() =>
                      props.navigation.navigate("forgot_password")
                    }>
                    <ForgetText>Esqueceu sua senha?</ForgetText>
                  </ForgetPass>
                </InputContainer>
              </View>
            </ScrollView>
            <ButtonsContainer>
              <Button
                loading={authContext.isAuthLoading}
                text="Entrar"
                onPress={handleSignIn}
              />
              <Button
                text="Cadastre-se"
                onPress={() => props.navigation.navigate("select_signup")}
                backgroundColor={Colors.white}
                textColor={Colors.main}
                underlayColor="#f1f1f1"
                shadowColor="#0000"
                shadowOffset={{ width: 0, height: 0 }}
                shadowRadius={0}
                elevation={0}
              />
            </ButtonsContainer>
          </View>
        </Container>
      </SafeAreaView>
    </View>
  );
};

export default Login;
